@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap");

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

/* reset stuff to test*/

/* html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
/* article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*/

/* reset stuff to test above */

/* @media (-webkit-min-device-pixel-ratio: 1.25) {
  :root {
    zoom: 0.8;
  }
} */

/* @media (min-resolution: 1.25dppx) {
  :root {
    zoom: 0.8;
  }
} */

/* @media (-webkit-min-device-pixel-ratio: 1.5) {
  :root {
    zoom: 0.65;
  }
} */

/* @media (min-resolution: 1.5dppx) {
  :root {
    zoom: 0.65;
  }
} */

/* @media (-webkit-min-device-pixel-ratio: 1.75) {
  :root {
    zoom: 0.55;
  }
} */

/* @media (min-resolution: 1.75dppx) {
  :root {
    zoom: 0.55;
  }
} */

/* @media (-webkit-min-device-pixel-ratio: 2) {
  :root {
    zoom: 0.5;
  }
} */

/* @media (min-resolution: 2dppx) {
  :root {
    zoom: 0.5;
  }
} */
/* Macbook 13 retina display app is 1299*/
/* @media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  :root {
    zoom: 0.8;
    /* -webkit-font-smoothing: unset; */
/* }
}  */

/* Possible smaller mac fix */
/* @media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (max-width: 1280px) {
  :root {
    zoom: 0.75;
    /* -webkit-font-smoothing: unset; */
/* }
} */

/* html,
body {
  width: 1300px;
  height: 900px;
  max-height: 1300px;
  max-width: 1300px;
}

:root {
  width: 1300px;
  height: 900px;
  max-height: 1300px;
  max-width: 1300px;
} */

@keyframes fade {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

.blink {
  animation: fade 2000ms infinite;
  -webkit-animation: fade 2000ms infinite;
}

.progress_slide {
  /* padding: 0.625rem; */
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 0px;
  direction: rtl;
}

.progress_slide::-webkit-scrollbar {
  width: 5px;
  /* Remove scrollbar space */
  /* background: transparent; Optional: just make scrollbar invisible */
}

.progress-flip {
  /* padding: 0.625rem; */
  display: flex;
  /* overflow-x: auto; */
  padding-top: 0px;
  direction: ltr;
}

/* .progress_flip::-webkit-scrollbar {
  width: 5px; /* Remove scrollbar space */
/* background: transparent; Optional: just make scrollbar invisible */
/* } */

body {
  background-color: #404040 !important;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

html {
  background-color: #404040 !important;
}

/* For MAC - Chakra ui also needs media query setup, review chakra queries */
/* @media screen and (min-width: 30em) {
  body {
    font-size: 14px;
  }
}

/* @media screen and (min-width: 78em) {
  body {
    font-size: 14px;
  }
} */

/* @media screen and (min-width: 98em) {
  body {
    font-size: 16px;
  }
} */
.circle_lang {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  line-height: 2.5rem;
  text-align: center;
  font-weight: bold;
  background-color: black;
  color: white;
}

.circle_black {
  align-self: stretch;
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 50%;
  background-color: black;
  margin-right: 0.313rem;
  margin-left: 0.313rem;
  padding: 1.563rem;
  color: white;
  line-height: 0px;
  text-align: center;
}

.progress_image {
  position: relative;
  max-width: 3.4rem;
  min-width: 3.4rem;
}

.progress_image:hover {
  position: relative;
  margin: 0px;
  padding: 0px;
  max-width: 4rem;
  width: 130px;
  overflow: hidden;

  z-index: 1000;
}

.progress-text {
  font-size: 0.68rem;
  width: 71px;
  /* overflow: hidden; */
}

.top_border {
  border-top: 0.125rem solid white;
  /* margin-top: 0px;
  padding-top: 0px; */
}

.active {
  /* border: 0.188rem solid green; */
  border-radius: 50%;
  box-shadow: inset 0px 0px 0.25rem 0.2rem greenyellow;
}

.not-active {
  /* border: 0.188rem solid green; */
  border-radius: 50%;
  box-shadow: inset 0px 0px 0.25rem 0.2rem tomato;
}

.preset-changed {
  /* border: 0.188rem solid green; */
  border-radius: 50%;
  box-shadow: inset 0px 0px 0.25rem 0.2rem #ffbf00;
}

.text-center {
  text-align: center;
}

.mt-4 {
  margin-top: 0.25rem;
}

.bar_image {
  width: 100%;
  height: 0.625rem;
  background-image: url(../assets/Calibrite-colour-strip.svg);
  background-size: 18.125rem;
}

hr.dashed {
  border-top: 0.063rem dashed white;
  padding-bottom: 0.938rem;
}

hr.thin {
  border-top: 0.063rem dashed white;
  padding-bottom: 0.2rem;
}

.button-box {
  display: flex;
  justify-content: space-between;
  max-width: 48.125rem;
  padding-right: 1.875rem;
}

.button-box-right {
  display: flex;
  justify-content: right;
  max-width: 48.125rem;
  padding-right: 1.875rem;
  flex: 1;
}

.button-measure {
  display: flex;
  justify-content: space-between;
}

.button-choice {
  display: flex;
  justify-content: space-around;
}

.dot {
  height: 28rem;
  width: 28rem;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.arrow {
  border: solid black;
  border-width: 0 0.25rem 0.25rem 0;
  display: inline-block;
  padding: 0.625rem;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.dropdown {
  background: #595959;
  color: whitesmoke;
  width: 100%;
  border-radius: 0.313rem;

  margin-bottom: 0px;

  /* position: absolute;
  max-width: 100px;
  left: 47%;
  overflow: hidden; */
}

/* 
.dropdown:active {
  outline-color: blue;
} */

.dropdown-custom {
  background: #595959;
  color: whitesmoke;
}

/* .dropdown:focus {
  background: #595959;
  box-shadow: none;
  outline: none;
} */

.dropdown-border {
  border: 0.063rem solid #e0e0e1;
  border-radius: 0.313rem;
  margin-top: 0.313rem;
  margin-bottom: 0px;
}

.error-text {
  color: tomato;
}

/* Overide inherited ui colors */
.MuiSlider-markLabel {
  color: #e0e0e1 !important;
}

.active-option {
  background-color: #e0e0e1;
  color: black;
}

.image-dropdown {
  display: flex;
  margin-bottom: 0px;
  flex-direction: column;
  justify-content: space-between;
}

.brightness {
  width: "50px !important";
}

.list {
  font-size: 0.8rem;
  list-style-type: none;
}

.wide {
  width: 100%;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  font-size: 14px;
  color: white;
  padding: 4px 7px;
  cursor: pointer;
  background-color: #3182ce;
  border-radius: 4px;
}

.button-wide {
  padding: 4px 15px;
}

.button-wider {
  padding: 5px 40px;
}

.hidden {
  opacity: 25%;
}

.styled-input {
  border: 0.5px solid white;
  width: 70%;
  padding: 5px;
  font-size: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
}

/* Calibration */
.footer {
  position: fixed;
  /* left: 0; */
  bottom: 50px;
  padding-right: 100px;
  width: 100%;

  /* color: white;
  text-align: center; */
}

/* Preset-Options */
.footer-flow {
  position: fixed;
  /* left: 0; */
  bottom: 40px;
  padding-right: 0px;
  width: 100%;
}

.loading-instruction {
  position: fixed;
  /* left: 0; */
  bottom: 50px;
  padding-right: 100px;
  width: 100%;
}

.footer-loading {
  position: fixed;
  /* left: 0; */
  bottom: 135px;
  padding-right: 100px;
  width: 100%;
}

.lt-footer-loading {
  position: fixed;
  bottom: 135px;
  padding-left: 100px;
  padding-right: 100px;
  width: 100%;
}

/* Windows laptop sd */

/* SD WINDOWS LAPTOP 150% & 175% */

/* @media screen and (min-width: 0px) {
  .dot-height {
    display: flex;
    height: 107%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: green; */
/* } */
/* } */

/* SD WINDOWS LAPTOP 125% */

/* @media screen and (min-width: 1530px) {
  .dot-height {
    display: flex;
    height: 92%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: purple; */
/* } */
/* } */

/* SD WINDOWS LAPTOP 100% */

/* @media screen and (min-width: 1920px) {
  .dot-height {
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: red; */
/* } */
/* } */

/* BEN Q SD 24 inch 125%*/

/* @media screen and (min-width: 2040px) {
  .dot-height {
    display: flex;
    height: 95%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: blue; */
/* } */
/* } */

/* BEN Q SD 24 inch 100%*/
/* @media screen and (min-width: 2550px) {
  .dot-height {
    display: flex;
    height: 80%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: blue; */
/* }  */
/* } */

.dot-height {
  position: fixed;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -51%);
  /* background-color: red; */
}

.dot-alternate-text {
  position: fixed;
  bottom: 15%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

/* .disabled-input:disabled {
  color: #e0e0e1;
  opacity: 1;
}

.disabled-input {
  opacity: 1;
} */
/* #colorPlotID {
  background-color: rgb(125, 125, 125);
  width: 100%;
}

#colorSwitchID {
  background-color: rgb(125, 125, 125);
  color: white;
  width: 100%;
  padding: 1em;
} */

.test {
  height: 100%;
  /* background-color: #3182ce; */
}

.fullscreen {
  position: fixed;
  top: 100px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  opacity: 1;
}

input[type="number"] {
  caret-color: transparent !important;
}

.MuiInput-input {
  cursor: default !important;
}

.MuiSlider-sizeMedium {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* Dropdown Button */
.lng-dropbtn {
  /* background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none; */
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.lng-dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.lng-dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.lng-dropdown-content {
  /* display: none; */
  position: absolute;
  right: 0px;
  top: 120%;
  background-color: #f1f1f1;
  border: 3px solid black;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.lng-dropdown-content-active {
  color: #595959;
  padding: 5px 5px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.lng-dropdown-content-active-selected {
  background-color: #595959;
  color: white;
  padding: 5px 5px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.lng-dropdown-content-active:hover {
  background-color: #ddd;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
/* .lng-show {
  display: block;
} */
.stringy {
  white-space: pre-wrap;
  overflow-y: auto;
  list-style-position: inside;
  height: 100%;
  padding-right: 4vw;
  /* height: 70%; */
  word-wrap: break-word;
}

.stringy::-webkit-scrollbar-thumb {
  background: #595959;
  border: 1px solid black;
}

.stringy::-webkit-scrollbar {
  width: 8px;
  /* height: 8px; */
  background-color: #404040;
  border: 1px solid black;
}

.patch-block::-webkit-scrollbar-thumb {
  background: #595959;
  border: 1px solid black;
}

.patch-block::-webkit-scrollbar {
  width: 8px;
  /* height: 8px; */
  background-color: #404040;
  border: 1px solid black;
}

.larger-check {
  height: 20px;
  width: 20px;
  margin-top: 5px;
}

.highlight {
  background-color: lightgrey;
  color: #404040;
  /* border: 1px solid black; */
  /* padding-left: 5px; */
}

.saved-list {
  overflow-y: overlay;
}

.saved-list::-webkit-scrollbar {
  width: 8px;
  /* height: 8px; */
  background-color: #404040;
  border: 1px solid black;
}

.saved-list::-webkit-scrollbar-thumb {
  background: #595959;
  border: 1px solid black;
}

.box-head {
  position: sticky;
  background-color: red;
  z-index: 3;
}

.preset-profile-list {
  font-size: 0.8rem;
  list-style-type: none;
}

.preset-profile-list:hover {
  background-color: lightgrey;
  color: #404040;
}

/* MONITOR UNIFORMITY CLASSES AND MEDIA QUERIES */

/* Windows laptop sd */

/* SD WINDOWS LAPTOP 150% & 175% */

@media screen and (min-width: 0px) {
  .uniformity-dot {
    height: 15rem;
    width: 15rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 25px;
    margin-top: 5px;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

/* BEN Q SD 24 inch 175%* and possible mac*/
@media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .uniformity-dot {
    height: 10rem;
    width: 10rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 20px;

    margin-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 900px) {
  .uniformity-dot {
    height: 8rem;
    width: 8rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 10px;

    margin-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1100px) {
  .uniformity-dot {
    height: 10rem;
    width: 10rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 15px;

    margin-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

/* BEN Q SD 24 inch 175%*/

@media screen and (min-width: 1460px) {
  .uniformity-dot {
    height: 10rem;
    width: 10rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 20px;

    margin-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1460px) {
  .uniformity-dot {
    height: 10rem;
    width: 10rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 20px;

    margin-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1480px) {
  .uniformity-dot {
    height: 10rem;
    width: 10rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 20px;

    margin-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

/* SD WINDOWS LAPTOP 125% */

@media screen and (min-width: 1530px) {
  .uniformity-dot {
    height: 15rem;
    width: 15rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 25px;

    margin-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1530px) {
  .uniformity-dot {
    height: 12rem;
    width: 12rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 20px;

    margin-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

/* BEN Q SD 24 inch 150% */

@media screen and (min-width: 1700px) {
  .uniformity-dot {
    height: 12rem;
    width: 12rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 20px;

    margin-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1700px) {
  .uniformity-dot {
    height: 13rem;
    width: 13rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 25px;

    margin-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1850px) {
  .uniformity-dot {
    height: 14rem;
    width: 14rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 25px;

    margin-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

/* SD WINDOWS LAPTOP 100% */

@media screen and (min-width: 1920px) {
  .uniformity-dot {
    height: 15rem;
    width: 15rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 25px;

    margin-top: 3px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

/* BEN Q SD 24 inch 125%*/

@media screen and (min-width: 2040px) {
  .uniformity-dot {
    height: 15rem;
    width: 15rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 30px;
    margin-top: 5px;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 2040px) {
  .uniformity-dot {
    height: 15rem;
    width: 15rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 25px;
    margin-top: 5px;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

/* BEN Q SD 24 inch 100%*/
@media screen and (min-width: 2400px) {
  .uniformity-dot {
    height: 15rem;
    width: 15rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 25px;
    margin-top: 5px;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 2400px) {
  .uniformity-dot {
    height: 20rem;
    width: 20rem;
    /* background-color: white; */
    border-radius: 50%;
    display: inline-block;
    padding: 0px;
  }

  .uniformity-inst {
    font-size: 30px;
    margin-top: 5px;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
  }
}

/* .chakra-portal {
  width: 100vw !important;
  background-color: red !important;
} */

.chakra-modal__content-container {
  width: 100% !important;
  /* background-color: red !important; */
}

.MuiSlider-mark {
  left: 0% !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.zoom-button {
  height: 40px;
  width: 60px;
}

.zoom-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.add-pointer:hover {
  cursor: pointer;
  opacity: 0.5;
}

.add-blocked:hover {
  cursor: not-allowed;
  /* opacity: 0.5; */
}

@keyframes updateButton {
  0% {
    background-color: #38a169;
  }

  50% {
    background-color: #00ff00;
  }

  100% {
    background-color: #38a169;
  }
}

html,
body {
  margin: 0;
  height: 100%;
  font-family: "Source Sans Pro" !important;
}

body {
  overflow: hidden;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.3) !important;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
